function load_script( src ) {
	var body = document.getElementsByTagName('BODY')[0];  
    var script = document.createElement('script'); 
    
    if ( wp.manifest ) {
        src = wp.manifest[src];
    }
    
	src = wp.stylesheet_directory_uri + 'dist/' + src;

    script.src = src;
    body.appendChild(script); 
}

function load_style( src ) {
	var head = document.getElementsByTagName('HEAD')[0];  
    var link = document.createElement('link'); 
    
    if ( wp.manifest ) {
        src = wp.manifest[src];
    }

	src = wp.stylesheet_directory_uri + 'dist/' + src;

    link.rel = 'stylesheet';  
    link.type = 'text/css'; 
    link.href = src;
    head.appendChild(link); 
}

// Conditionally load slick carousel
var $carousels = document.querySelectorAll('.js-split-carousel, .js-gallery-carousel');

if ( $carousels.length ) {
	load_style('css/slick.min.css');
	load_script('js/slick.min.js');
}
