// Animate the scroll position of a reel container

(function( $ ) {

	const ANIMATION_DURATION = 300;
	const REEL_ARROWS_HTML = `
		<div class="reel__arrows">
			<button type="button" class="arrow-button-prev js-reel-control" data-reel-dir="-1" aria-label="Scroll to left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.005 25.394" aria-hidden="true" focusable="false"><path d="M15,2.31,12.7,0,0,12.7,12.7,25.39,15,23.08,4.62,12.69Z"/></svg></button>

			<button type="button" class="arrow-button-next js-reel-control" data-reel-dir="+1" aria-label="Scroll to right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.005 25.394" aria-hidden="true" focusable="false"><path d="M0 23.085l2.309 2.309 12.7-12.7L2.309 0 0 2.309 10.388 12.7z"/></svg></button>
		</div> <!-- /.reel__arrows -->`;

	let $reels = $('.js-reel');
	let $reel_buttons = $('.js-reel-control');

	if ( !$reels.length ) {
		return;
	}


	/**
	 * Calculate the new position to scroll to based on the 
	 * current position and direction. 
	 * Will always fix to the left edge of a card.
	 * @param  {jQuery} $reel A Reel component (scroll container with overflow).
	 * @param  {Number} dir   -1 for left, +1 for right.
	 * @return {Number}
	 */
	function getNewScrollLeft( $reel, dir ) {
		let $items = $reel.children();

		let itemOffsets = $items.map((index, item) => {
			return item.getBoundingClientRect().left - $reel[0].getBoundingClientRect().left;
		}).filter((index, offset) => {
			return dir > 0 ? offset >= 1 : offset <= -1;
		});

		if ( !itemOffsets.length ) {
			return 0;
		}

		let offset = 0;

		if ( dir > 0 ) {
			offset = itemOffsets[0];
		} else {
			offset = itemOffsets[itemOffsets.length - 1];
		}

		return $reel.scrollLeft() + offset;
	}

	$reels.each(function(index, reel) {
		let $reel = $(reel);
		let $actions = $reel.siblings('.js-reel-actions');

		let reel_is_scrollable = $reel[0].scrollWidth - $reel[0].offsetWidth > 0;

		// if not scrollable arrows are not required
		if ( !reel_is_scrollable ) {
			// additionally if no other actions are present, hide the whole container
			if ( 0 === $actions.children().length ) {
				$actions[0].hidden = true;
			}

			return;
		} 

		let $arrows = $(REEL_ARROWS_HTML);
		$arrows.find('.js-reel-control').on('click touchend', function(event) {
			let $button = $(event.target).closest('.js-reel-control');

			let dir = $button.data('reelDir');

			$reel.animate({
				scrollLeft: getNewScrollLeft($reel, dir)
			}, ANIMATION_DURATION);
		});

		$actions.prepend($arrows);

		$reel.addClass('is-enhanced');
	});
	
})( jQuery );
