// Project filters and view toggle

(function( $ ) {

	/**
	 * Toggle view between list/map.
	 */
	let $listView = $('#list-view');
	let $mapView = $('#map-view');
	let $viewToggles = $('.js-view-toggle');

	if ( !$listView.length || !$mapView.length || !$viewToggles.length ) {
		return;
	}

	function updateToggleState( state ) {
		// any incorrect input defaults to left state
		$('.js-toggle-switch').attr('data-state', 'right' === state ? 'right' : 'left');
	}

	function showList() {
		$listView[0].hidden = false;
		$mapView[0].hidden = true;

		updateToggleState('right');
	}

	function showMap() {
		$listView[0].hidden = true;
		$mapView[0].hidden = false;

		let $map = $mapView.find('.js-google-map');
		if ( $map.length && !$map.hasClass('map-initialised') ) {
			create_map( $map[0] );
		}

		updateToggleState('left');
	}


	$viewToggles.on('change', function(event) {
		let newView = event.target.value;

		if ( 'map' === newView ) {
			showMap();
		}

		if ( 'list' === newView ) {
			showList();
		}
	});

	$viewToggles.on('focus', function() {
		$viewToggles.parent().addClass('focus-within');
	});

	$viewToggles.on('blur', function() {
		$viewToggles.parent().removeClass('focus-within');
	});

	$('.js-toggle-switch').click(function(event) {
		let state = $viewToggles.filter(':checked').val();

		if ( 'list' === state ) {
			$viewToggles.filter('#map-view-toggle')[0].checked = true;
			showMap();
		} else {
			$viewToggles.filter('#list-view-toggle')[0].checked = true;
			showList();
		}
	});
	
})( jQuery );
