/**
 * Toggle property search filter menu on mobile.
 */

( function( $, window, document, undefined ) {

	var $toggle = $('.js-filter-toggle');
	var $filters = $('.project-list__filters');
	var $main = $('#main');
	var $form = $('form.project-filters');
	var $close = $('.js-filters-close');
	var $pld = $('.project-list__description');

	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;

	$toggle.on('click', function() {
		if ( $filters.length && !$filters.hasClass('open') ) {
			openFilters();
		} else if ( $filters.length ) {
			closeFilters();
		} 
	});

	$close.on('click', function() {
		closeFilters();
	});

	$form.submit( function() {
		closeFilters();
	});

	function openFilters() {
		$filters.addClass('open');
		$main.addClass('filter-menu-open');
		disableBodyScroll($filters);
	}

	function closeFilters() {
		$filters.removeClass('open');
		setTimeout( function() { 
			$main.removeClass('filter-menu-open');
		}, 200);
		enableBodyScroll($filters);
	}
	
} )( jQuery, window, document );
