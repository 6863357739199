// Lazyload video iframe when content clicked

(function( $ ) {
	
	let $lazyvideos = $('[data-lazyvideo]');

	if ( $lazyvideos.length ) {
		$lazyvideos.click(function(event) {
			let $target = $(event.target).closest('[data-lazyvideo]');
			let $video = $($target.data('lazyvideo'));

			$target.find('img').remove();
			$target.append($video);

			$target.removeAttr('data-lazyvideo');
			$target.find('button').remove();
			$lazyvideos = $lazyvideos.not($target);
		});
	}

})( jQuery );
